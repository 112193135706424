<template>
  <div>
    <el-col class="el-col-24 optional-search-box" style="margin-bottom: 12px">
      <el-col class="el-col-sm-24 el-col-md-24 el-col-lg-5"
              style="margin-top: 5px; display: flex; align-items: center">
        <el-col class="el-col-sm-5 el-col-md-5 el-col-lg-4">题目:</el-col>
        <el-col class="el-col-sm-19 el-col-md-18 el-col-lg-18">
          <el-input v-model="searchCondition.topic" size="small" placeholder="请输入题目"></el-input>
        </el-col>
      </el-col>
      <el-col class="el-col-sm-24 el-col-md-24 el-col-lg-5"
              style="margin-top: 5px; display: flex; align-items: center">
        <el-col class="el-col-sm-5 el-col-md-5 el-col-lg-5">题型：</el-col>
        <el-col class="el-col-sm-19 el-col-md-18 el-col-lg-18">
          <el-select v-model="searchCondition.type"
                     filterable
                     clearable
                     size="small"
                     placeholder="请选择题型"
          >
            <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.text"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-col>
      <el-col class="el-col-sm-24 el-col-md-24 el-col-lg-5"
              style="margin-top: 5px; display: flex; align-items: center">
        <el-col class="el-col-sm-5 el-col-md-5 el-col-lg-5">题库：</el-col>
        <el-col class="el-col-sm-19 el-col-md-18 el-col-lg-18">
          <el-select v-model="searchCondition.qb_id"
                     filterable
                     clearable
                     size="small"
                     placeholder="请选择题库"
          >
            <el-option
                v-for="item in questionBankList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-col>
      <el-col class="el-col-sm-24 el-col-md-24 el-col-lg-5"
              style="margin-top: 5px; display: flex; align-items: center">
        <el-col class="el-col-sm-5 el-col-md-5 el-col-lg-5">类型：</el-col>
        <el-col class="el-col-sm-19 el-col-md-18 el-col-lg-18">
          <el-select v-model="searchCondition.bt_id"
                     filterable
                     clearable
                     size="small"
                     placeholder="请选择业务类型"
          >
            <el-option
                v-for="item in businessTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-col>
      <el-col class="el-col-sm-24 el-col-md-10 el-col-lg-4"
              style="margin-top: 5px; display: flex; justify-content: flex-end">
        <el-button type="primary" @click="onSearchClick" size="small">搜索</el-button>
        <el-button @click="onResetSearchClick" size="small" style="margin-left: 8px">重置</el-button>
      </el-col>
    </el-col>

    <div>
      <span>已选择<span style="color:#0099ff;padding: 0 6px">{{ selectQuestionIds.length }}</span>项</span>
    </div>

    <ape-table ref="apeTable" :data="questionList" :columns="questionColumns"
               :loading="loadingStatus"
               :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row
               @select="handleSelectionChange" @selectAllOnce="onHandleSelectionAllChange">
      <el-table-column slot="first-column"
                       type="selection"
                       width="55">
      </el-table-column>
    </ape-table>


    <div style="display: flex;justify-content: flex-end;padding-top: 24px">
      <el-button @click="onDialogCancel">取 消</el-button>
      <el-button type="primary" @click="onDialogConfirm">确定</el-button>
    </div>
  </div>
</template>

<script>
const NO_DEL = "no_del";
const DEL = "del";
import ApeTable from "@/components/ApeTable";

export default {
  name: "selected-real-question",
  components: {ApeTable},
  props: {
    typeList: {
      type: Array,
      default: () => {
        return []
      }
    },
    questionBankList: {
      type: Array,
      default: () => {
        return []
      }
    },
    businessTypeList: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      //题目数据
      questionColumns: [
        {
          title: '所属题库',
          value: 'question_bank_name',
        },
        {
          title: '业务类型',
          value: 'business_type_name'
        },
        {
          title: '题目',
          value: 'topic'
        },
        {
          title: '题型',
          value: 'type_alias',
        }
      ],
      questionList: [],
      loadingStatus: false,
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
      },
      //搜索条件
      searchCondition: {},
      selectQuestionIds: [],
      cId: ''
    }
  },

  mounted() {
  },

  methods: {
    setCId(cId){
      this.cId = cId
      this.iniRealQuestionPool(DEL)
    },
    //待选题目
    async iniRealQuestionPool(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);
      for (let param in params) {
        inputData[param] = params[param];
      }
      if (type === DEL) {
        this.$refs['apeTable'].resetCurPageSize()
        inputData["current_page"] = 1
      }
      inputData["c_id"] = this.cId
      let {list, pages} = await this.$api.getRealQuestionPool(inputData);
      this. questionList = [];
      this.$nextTick(() => {
        this.questionList = list
      });
      this.pagingData.total = pages.total;
      this.loadingStatus = false
      setTimeout(() => {
        for (let selected of this.selectQuestionIds) {
          this.$refs.apeTable.defaultCheckedRow(selected.id, true)
        }
      }, 200)
    },
    //切换页码
    async switchPaging() {
      this.iniRealQuestionPool(NO_DEL, this.searchCondition)
    },
    // 全选
    onHandleSelectionAllChange(arr) {
      for (let i of this.questionList) {
        let index = this.selectQuestionIds.findIndex(item => item.id === i.id)
        if (index > -1) {
          this.selectQuestionIds.splice(index, 1)
        }
      }

      if (arr.length > 0) {
        for (let id of arr) {
          this.selectQuestionIds.push({id: id})
        }
      }
    },

    // 单选
    handleSelectionChange(id, selected) {
      if (selected) {
        this.selectQuestionIds.push({id: id})
      } else {
        let pos = this.selectQuestionIds.findIndex(item => item.id === id)
        if (pos > -1) {
          this.selectQuestionIds.splice(pos, 1)
        }
      }
    },
    //搜索
    onSearchClick() {
      this.$refs['apeTable'].resetCurPageSize()
      this.iniRealQuestionPool(DEL, this.searchCondition)
    },
    //重置
    onResetSearchClick() {
      this.searchCondition = {}
      this.iniRealQuestionPool(DEL)
    },
    //取消
    onDialogCancel() {
      this.$emit("cancel")
    },
    //确定
    async onDialogConfirm() {
      let data = {
        current_page: 1,
        page_size: 10000
      }

      let arr = []
      let {list} = await this.$api.getRealQuestionPool(data);
      for (let selected of this.selectQuestionIds) {
        let item = list.find(c => c.id === selected.id)
        if (item) {
          arr.push(item)
        }
      }
      console.log(arr)
      this.$emit("confirm", arr)
    }
  }


}
</script>

<style scoped>

</style>