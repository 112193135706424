<template>
  <div>
    <page-header-layout>
      <div class="main-page-content">
        <el-row :gutter="48">
          <el-col class="el-col-sm-24 el-col-md-12 el-col-lg-8 el-col-offset-1 ">
            <div class="el-col-sm-pull-2">
              <el-button type="primary" @click="onSelectQuestion">选题</el-button>
            </div>
            <el-table
                :data="summaryQuestionList"
                show-summary
            >
              <el-table-column v-for="(item,index) in summaryColumns"
                               :key="index"
                               :prop="item.value"
                               :label="item.title"
                               :width="item.width">
              </el-table-column>
            </el-table>
          </el-col>

          <el-col class="el-col-sm-24 el-col-md-23 el-col-lg-15 " style="margin-top: 20px">
            <span style="color: #0099ff">已选题目</span>
            <el-form
                :inline="true"
                :model="searchForm"
                class="search-form"
                label-width="35px"
                size="mini"
                ref="search-form"
            >
              <el-form-item label="题型" prop="type">
                <el-select v-model="searchForm.type" clearable placeholder="请选择题型">
                  <el-option v-for="item in typeList"
                             :key="item.id"
                             :label="item.text"
                             :value="item.id"
                  />
                </el-select>
              </el-form-item>

              <el-form-item label="类型" prop="bt_id">
                <el-select v-model="searchForm.bt_id" clearable placeholder="请选择业务类型">
                  <el-option
                      v-for="item in businessTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>

              <el-form-item label="题目" prop="topic">
                <el-input v-model="searchForm.topic" clearable placeholder="请输入题目"/>
              </el-form-item>

              <el-form-item style="float: right">
                <el-button @click="searchFormReset" icon="el-icon-refresh-right" plain>重置</el-button>
              </el-form-item>
            </el-form>

            <div style="display:flex;justify-content: space-between">
              <div style="display: flex;align-items: center">
                <span>已选择<span style="color: #0099ff;padding: 0 6px">{{ selectQuestionIds.length }}</span>项</span>
              </div>
              <div>
                <el-button
                    type="danger"
                    size="mini"
                    @click="deleteSelected"
                    :disabled="loadingStatus || selectQuestionIds.length <= 0"
                >删除选中
                </el-button>
                <el-button v-if="(searchForm.type || searchForm.bt_id || this.searchForm.qb_id) && pagingData.total > 0"
                           type="danger"
                           size="mini"
                           @click="deleteAllResult"
                           style="margin-left: 5px"
                >删除搜索结果
                </el-button>
              </div>
            </div>

            <ape-table ref="apeTable" :data="questionAddTableList" :columns="selectedQuestionColumns"
                       :loading="loadingStatus"
                       :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row
                       @select="handleSelectionChange" @selectAllOnce="onHandleSelectionAllChange">
              <el-table-column slot="first-column"
                               type="selection"
                               width="55">
              </el-table-column>
              <el-table-column
                  v-if="buttonType=='icon'"
                  label="操作"
                  width="100"
                  fixed="right"
              >
                <template slot-scope="scope" v-if="userPermissions.length > 0">
                  <div>
                    <el-tooltip effect="dark" content="修改" placement="top-start"
                                v-if="userPermissions.indexOf('hr_edit') != -1">
                      <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row)"></el-button>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="删除" placement="top-start"
                                v-if="userPermissions.indexOf('hr_delete') != -1">
                      <el-button type="danger" size="mini" @click="deleteButton(scope.row.id)"
                                 icon="el-icon-delete"></el-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  v-if="buttonType=='text'"
                  label="操作"
                  width="150"
              >
                <template slot-scope="scope">
                  <div>
                    <el-tooltip effect="dark" content="修改" placement="top-start"
                                v-if="userPermissions.indexOf('hr_edit') != -1">
                      <el-button size="mini" type="text" @click="editButton(scope.row)"></el-button>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="删除" placement="top-start"
                                v-if="userPermissions.indexOf('hr_delete') != -1">
                      <el-button type="text" size="mini" @click="deleteButton(scope.row.id)">
                      </el-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </ape-table>

            <!--            <selected-question-table-->
            <!--                :id="cId"-->
            <!--                :questionData="questionAddTableList"-->
            <!--                :loading="selectedQuestionTableLoading"-->
            <!--                :pageInfo="pageInfo"-->
            <!--                :isSelected="!!selectQuestionIds.length"-->
            <!--                @change="onPageSizeChange"-->
            <!--                @select-list="getSelectedQuestionList"-->
            <!--                @modify="editButton"-->
            <!--                @load="loadMoreSelectQuestion"-->
            <!--                @deleteSelected="deleteSelected"-->
            <!--                @deleteAll="deleteAllResult"-->
            <!--            >-->
            <!--              <el-table-column-->
            <!--                  slot="last-column"-->
            <!--                  label="操作"-->
            <!--                  width="150px"-->
            <!--                  fixed="right">-->
            <!--                <template slot-scope="scope">-->
            <!--                                    <span>-->
            <!--                                        <el-tooltip effect="dark" content="修改" placement="top-start"-->
            <!--                                                    v-if="userPermissions.indexOf('exercise_question_type_edit') != -1">-->
            <!--                                            <el-button size="mini" icon="el-icon-edit"-->
            <!--                                                       @click="editButton(scope.row)"></el-button>-->
            <!--                                        </el-tooltip>-->
            <!--                                        <el-tooltip effect="dark" content="删除" placement="top-start">-->
            <!--                                        <span>-->
            <!--                                            <el-button-->
            <!--                                                v-if="userPermissions.indexOf('exercise_question_type_delete') != -1"-->
            <!--                                                type="danger" size="mini" @click="deleteButton(scope.row.id)"-->
            <!--                                                icon="el-icon-delete">-->
            <!--                                            </el-button>-->
            <!--                                        </span>-->
            <!--                                        </el-tooltip>-->
            <!--                                    </span>-->
            <!--                </template>-->
            <!--              </el-table-column>-->
            <!--            </selected-question-table>-->
          </el-col>

        </el-row>
      </div>
    </page-header-layout>

<!--        <el-dialog :visible.sync="modelVisible" top="5vh" append-to-body title="可选题目" width="70%" @close="onDialogClose"-->
<!--                   :destroy-on-close="true">-->
<!--          <el-col class="el-col-24 optional-search-box" style="margin-bottom: 12px">-->
<!--            <el-col class="el-col-sm-24 el-col-md-24 el-col-lg-5"-->
<!--                    style="margin-top: 5px; display: flex; align-items: center">-->
<!--              <el-col class="el-col-sm-5 el-col-md-5 el-col-lg-4">题目:</el-col>-->
<!--              <el-col class="el-col-sm-19 el-col-md-18 el-col-lg-18">-->
<!--                <el-input v-model="searchCondition.topic" size="small" placeholder="请输入题目"></el-input>-->
<!--              </el-col>-->
<!--            </el-col>-->
<!--            <el-col class="el-col-sm-24 el-col-md-24 el-col-lg-5"-->
<!--                    style="margin-top: 5px; display: flex; align-items: center">-->
<!--              <el-col class="el-col-sm-5 el-col-md-5 el-col-lg-5">题型：</el-col>-->
<!--              <el-col class="el-col-sm-19 el-col-md-18 el-col-lg-18">-->
<!--                <el-select v-model="searchCondition.type"-->
<!--                           filterable-->
<!--                           clearable-->
<!--                           size="small"-->
<!--                           placeholder="请选择题型"-->
<!--                >-->
<!--                  <el-option-->
<!--                      v-for="item in typeList"-->
<!--                      :key="item.id"-->
<!--                      :label="item.text"-->
<!--                      :value="item.id"-->
<!--                  >-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-col>-->
<!--            </el-col>-->
<!--            <el-col class="el-col-sm-24 el-col-md-24 el-col-lg-5"-->
<!--                    style="margin-top: 5px; display: flex; align-items: center">-->
<!--              <el-col class="el-col-sm-5 el-col-md-5 el-col-lg-5">题库：</el-col>-->
<!--              <el-col class="el-col-sm-19 el-col-md-18 el-col-lg-18">-->
<!--                <el-select v-model="searchCondition.qb_id"-->
<!--                           filterable-->
<!--                           clearable-->
<!--                           size="small"-->
<!--                           placeholder="请选择题库"-->
<!--                >-->
<!--                  <el-option-->
<!--                      v-for="item in questionBankList"-->
<!--                      :key="item.value"-->
<!--                      :label="item.label"-->
<!--                      :value="item.value"-->
<!--                  ></el-option>-->
<!--                </el-select>-->
<!--              </el-col>-->
<!--            </el-col>-->
<!--            <el-col class="el-col-sm-24 el-col-md-24 el-col-lg-5"-->
<!--                    style="margin-top: 5px; display: flex; align-items: center">-->
<!--              <el-col class="el-col-sm-5 el-col-md-5 el-col-lg-5">类型：</el-col>-->
<!--              <el-col class="el-col-sm-19 el-col-md-18 el-col-lg-18">-->
<!--                <el-select v-model="searchCondition.bt_id"-->
<!--                           filterable-->
<!--                           clearable-->
<!--                           size="small"-->
<!--                           placeholder="请选择业务类型"-->
<!--                >-->
<!--                  <el-option-->
<!--                      v-for="item in businessTypeList"-->
<!--                      :key="item.value"-->
<!--                      :label="item.label"-->
<!--                      :value="item.value"-->
<!--                  ></el-option>-->
<!--                </el-select>-->
<!--              </el-col>-->
<!--            </el-col>-->
<!--            <el-col class="el-col-sm-24 el-col-md-10 el-col-lg-4"-->
<!--                    style="margin-top: 5px; display: flex; justify-content: flex-end">-->
<!--              <el-button type="primary" @click="onSearchClick" size="small">搜索</el-button>-->
<!--              <el-button @click="onResetSearchClick" size="small" style="margin-left: 8px">重置</el-button>-->
<!--            </el-col>-->
<!--          </el-col>-->


<!--          <el-table-->
<!--              height="55vh"-->
<!--              v-el-table-infinite-scroll="loadMoreQuestionData"-->
<!--              :data="questionList"-->
<!--              v-loading="loadingStatus"-->
<!--              @selection-change="getSelectList"-->
<!--              ref="elTable"-->
<!--          >-->
<!--            <el-table-column-->
<!--                type="selection"-->
<!--                width="55">-->
<!--            </el-table-column>-->
<!--            <el-table-column v-for="(item,index) in questionColumns"-->
<!--                             :key="index"-->
<!--                             :prop="item.value"-->
<!--                             :label="item.title"-->
<!--                             :width="item.width">-->
<!--            </el-table-column>-->
<!--          </el-table>-->
<!--          <div slot="footer" class="dialog-footer">-->
<!--            <el-button @click="onDialogCancel">取 消</el-button>-->
<!--            <el-button type="primary" @click="onDialogConfirm">确定</el-button>-->
<!--          </div>-->
<!--        </el-dialog>-->
    <el-dialog :visible.sync="scoreModelVisible" :close-on-click-modal="false" title="修改分数" :destroy-on-close="true">
      <el-form ref="scoreForm" :model="modifyScoreForm" label-width="80px">
        <el-form-item label="所属题库">
          <el-input v-model="modifyScoreForm.question_bank_name" disabled/>
        </el-form-item>

        <el-form-item label="题目">
          <el-input v-model="modifyScoreForm.topic" disabled/>
        </el-form-item>

        <el-form-item label="题型">
          <el-input v-model="modifyScoreForm.type_alias" disabled/>
        </el-form-item>

        <el-form-item label="分数">
          <el-input v-model="modifyScoreForm.score"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </el-form-item>

        <el-form-item label="顺序">
          <el-col class="el-col-16">
            <el-input v-model="modifyScoreForm.order"
                      onkeyup="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="onModifyDialogCancel">取 消</el-button>
        <el-button type="primary" @click="onModifyDialogConfirm">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="modelVisible" :close-on-click-modal="false" title="选择题目" style="margin: 10px"
               :append-to-body="true" fullscreen
               @opened="modelVisibleOpened"
               @closed="onDialogClose" :destroy-on-close="true">
      <selected-real-question :type-list="typeList" :question-bank-list="questionBankList" :business-type-list="businessTypeList"
      @confirm="onDialogConfirm" @cancel="onDialogCancel" ref="selectedRealRef"></selected-real-question>
    </el-dialog>
  </div>

</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable';
import elTableInfiniteScroll from 'el-table-infinite-scroll';
import {mapGetters} from 'vuex'
import SearchMixins from '../mixins/SearchMixins';
import searchForm from "../mixins/searchForm";
import SelectedQuestionTable from '@/components/selectedQuestionTable'
import SelectedRealQuestion from '@/pages/history_real/components/selectedRealQuestion'

const NO_DEL = "no_del";
const DEL = "del";
const IS_SUCCESS = "ok";
export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    SelectedQuestionTable,
    SelectedRealQuestion
  },

  mixins: [SearchMixins, searchForm],

  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },
  data() {
    return {
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },
      modifyScoreForm: {
        score: "",
        order: ""
      },
      loadingStatus: true,
      summaryColumns: [
        {
          title: "题型",
          value: "type_alias"
        },
        {
          title: "题数",
          value: "total_count"
        },
        {
          title: "累计分数",
          value: "total_score"
        }
      ],
      selectedQuestionColumns: [
        {
          title: '所属题库',
          value: 'question_bank_name',
        },
        {
          title: '业务类型',
          value: 'business_type_name'
        },
        {
          title: '题目',
          value: 'topic',
        },
        {
          title: '题型',
          value: 'type_alias',
        },
        {
          title: '分数',
          value: 'score',
          width: '80'
        }
      ],
      //题目数据
      questionColumns: [
        {
          title: '所属题库',
          value: 'question_bank_name',
        },
        {
          title: '业务类型',
          value: 'business_type_name'
        },
        {
          title: '题目',
          value: 'topic_alias',
          value_alias: 'topic'
        },
        {
          title: '题型',
          value: 'type_alias',
          value_alias: 'type',
        }
      ],
      questionList: [],
      //可选题目
      modelVisible: false,
      tableSelectList: [],
      scoreModelVisible: false,//修改分数弹框
      totalCount: null,
      //已选题目
      questionAddTableList: [],
      cId: "",
      //已选分页信息
      pageInfo: {
        page_size: 30,
        current_page: 1,
        total: 0
      },
      //搜索条件
      searchCondition: {
        type: "",
        qb_id: "",
        bt_id: "",
        topic: ""
      },
      businessName: "",
      searchParams: {},
      qtqId: "",
      summaryQuestionList: [],
      qbId: "",

      //已选题目表格加载
      selectedQuestionTableLoading: false,

      searchForm: {
        type: '',
        bt_id: '',
        topic: '',
        qb_id: '',
      },
      selectQuestionIds: [],
    }
  },
  mounted() {
    this.cId = this.$route.params.c_id;
    this.qbId = this.$route.params.qb_id;
    this.resetBusinessTypeList()
    this.getQuestionTypeList()
    //已选题目
    this.resetRealQuestionList(DEL);
    //已选题目统计
    this.summaryRealQuestion();
  },
  watch: {
    searchForm: {
      handler(val) {
        this.searchFormSearch()
      },
      deep: true
    }
  },
  methods: {
    //修改分数
    editButton(row) {
      this.qtqId = row.id;
      this.scoreModelVisible = true;

      for (let key in row) {
        if (row.hasOwnProperty(key)) {
          this.modifyScoreForm[key] = row[key];
        }
      }
    },

    //删除已选题目
    deleteButton(id) {
      this.$confirm('确认删除该题吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let params = {}
        params.id = [id]
        params.c_id = this.cId
        let info = await this.$api.deleteRealQuestion(params);
        if (info === IS_SUCCESS) {
          this.$nextTick(() => {
            this.$message.success("删除成功");
            this.selectQuestionIds = this.selectQuestionIds.filter(item => item.id !== id)
            this.summaryRealQuestion();
            this.resetRealQuestionList(DEL, this.searchForm);
          })
        } else {
          this.$message.error(info)
        }
      })
    },

    //修改分数和顺序弹框
    async onModifyDialogConfirm() {
      let params = {};

      if (this.modifyScoreForm['score']) {
        params.score = this.modifyScoreForm.score;
      }

      if (this.modifyScoreForm['order']) {
        params.order = this.modifyScoreForm.order;
      }

      if (!this.qtqId) {
        this.$message("题型ID不存在");
        return;
      }
      params.id = this.qtqId;

      let data = await this.$api.upDateRealQuestion(params);

      if (data) {
        this.$message.success("修改成功");
      }
      this.$refs['scoreForm'].resetFields();
      this.scoreModelVisible = false;
    },

    //取消修改分数弹框
    onModifyDialogCancel() {
      this.scoreModelVisible = false;
    },

    //弹框
    onSelectQuestion() {
      this.modelVisible = true;
      this.tableSelectList = [];
      this.$nextTick(() => {
        this.iniRealQuestionPool(DEL)
      });
    },
    modelVisibleOpened(){
      this.$refs.selectedRealRef.setCId(this.cId)
    },

    //题目统计
    async summaryRealQuestion() {
      this.loadingStatus = true;
      if (!this.cId) {
        this.$message("题型不存在");
        return;
      }

      let id = this.cId;
      let data = {
        c_id: id,
        qb_id: this.qbId
      };
      let {list} = await this.$api.summaryRealQuestion(data);

      this.$nextTick(() => {
        this.summaryQuestionList = list;
        this.loadingStatus = false
      });
    },

    //待选题目
    async iniRealQuestionPool(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$utils.deepCopy(this.pagingInfo);
      inputData.c_id = this.cId;
      for (let param in params) {
        let value = params[param]
        value && (inputData[param] = params[param]);
      }

      let {list, pages} = await this.$api.getRealQuestionPool(inputData);
      this.totalCount = pages.total;
      this.$nextTick(() => {
        if (type === DEL) {
          this.questionList = list;
        } else {
          for (let question of list) {
            this.questionList.push(question);
          }
        }

        if (this.questionList.length >= this.totalCount) {
          this.$message("已经到底了");
        }

        this.loadingStatus = false
      });
    },

    // //已选题目
    // async iniRealQuestionList(type) {
    //     this.loadingStatus = true;
    //     let pagingInfo = this.$refs['existsApeTable'].getPagingInfo(type);
    //     if (!this.cId) {
    //         this.$message("题型ID不存在");
    //         return;
    //     }
    //
    //     let data = {
    //         page_size: pagingInfo.page_size,
    //         current_page: pagingInfo.current_page,
    //         c_id: this.cId,
    //     };
    //
    //     let {list, pages} = await this.$api.getRealQuestionList(data);
    //
    //     this.$nextTick(() => {
    //         this.questionAddTableList = list;
    //     });
    //     this.existsPagingData.total = pages.total;
    //     this.existsPagingData.offset = pages.offset;
    //     this.loadingStatus = false
    // },

    //获取题目
    getSelectList(list) {
      this.tableSelectList = list;
    },

    //切换页码
    // async switchPaging() {
    // this.iniRealQuestionList()
    // },

    //弹框确定
    async onDialogConfirm(list) {
      this.modelVisible = false;

      if (!this.cId) {
        this.$message("题型Id不能为空");
        return;
      }
      if (list.length <= 0) {
        this.$message("请选择要添加的题目");
        return;
      }

      let data = {
        c_id: this.cId,
        questions: list
      };

      this.loadingStatus = true
      await this.$api.storeRealQuestion(data);
      await this.summaryRealQuestion();
      await this.iniRealQuestionPool();
      await this.resetRealQuestionList(DEL);
      this.$nextTick(() => {
        this.loadingStatus = false
      })
    },

    //弹框取消
    onDialogCancel() {
      this.tableSelectList = [];
      this.modelVisible = false;
      this.searchCondition = {
        type: "",
        qb_id: "",
        bt_id: "",
        topic: ""
      };

      this.curQbId = "";
      this.btId = "";
      this.businessName = "";
    },

    //加载
    loadMoreQuestionData() {
      if (this.totalCount == null) {
        return;
      }

      if (this.questionList.length >= this.totalCount) {
        return;
      }

      if (this.loadingStatus) {
        return;
      }
      this.$message.success('加载下一页');
      this.pagingInfo.current_page++;
      this.iniRealQuestionPool(NO_DEL, this.searchParams);
    },

    //搜索
    onSearchClick() {
      this.pagingInfo.current_page = 1;
      this.iniRealQuestionPool(DEL, this.searchCondition);
      this.resetRealQuestionList(DEL);
    },

    //重置
    onResetSearchClick() {
      this.searchCondition = {
        type: "",
        qb_id: "",
        bt_id: "",
        topic: ""
      };

      this.curQbId = "";
      this.btId = "";
      this.businessName = "";
      this.iniRealQuestionPool(DEL);
    },

    handleQuestionBankSelect(item) {
      this.curQbId = item;
    },

    // 删除选中
    async deleteSelected() {
      if (this.selectQuestionIds.length <= 0) return
      let content = `确认删除已选中的<span style='color: red'>${this.selectQuestionIds.length}</span>道题目吗？`
      this.$confirm(content, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(async () => {
        let params = {};
        let ids = []
        for (let selected of this.selectQuestionIds) {
          ids.push(selected.id)
        }
        params.id = ids;
        params.c_id = this.cId;
        try {
          this.loadingStatus = true
          let result = await this.$api.deleteRealQuestion(params);
          this.selectQuestionIds = []
          result && this.$message.success("删除成功");
          await this.resetRealQuestionList(DEL, this.searchForm)
          await this.summaryRealQuestion()
          this.loadingStatus = false
        } catch (e) {
          this.$message.error('删除失败')
          this.loadingStatus = false
        }
      })
    },
    setConfirmContent() {
      let {bt_id, type, topic} = this.searchForm
      let sortsTip = '';
      let typeTip = '';
      let topicTip = '';
      let content = '';
      if (bt_id) {
        this.businessTypeList.filter(item => {
          if (item.value === bt_id) {
            sortsTip = `业务类型为："<span style='color: red'>${item.label}</span>" `
          }
        })
      }
      if (type) {
        this.typeList.filter(item => {
          if (item.id === type) {
            typeTip = `题型为："<span style='color: red'>${item.text}</span>" `
          }
        })
      }
      if (topic) {
        topicTip = `题目包含关键字："<span style='color: red'>${topic}</span>" `
      }
      if (!sortsTip && !typeTip && !topicTip) {
        content = `确认删除所有题目吗？共<span style='color: red'>${this.pagingData.total}</span>道`
      } else {
        content = '确认删除' + typeTip + sortsTip + topicTip + `的共<span style='color: red'>${this.pagingData.total}</span>道题目吗？`
      }

      if (this.pagingData.total === 0) {
        return 0
      }

      return content
    },
    // 删除搜索结果
    async deleteAllResult() {
      if (!this.searchForm.type && !this.searchForm.bt_id && !this.searchForm.qb_id) {
        this.$message.error("请设置查询条件")
        return
      }

      let content = this.setConfirmContent()
      if (content === 0) {
        this.$message.error("暂无数据！")
        setTimeout(() => {
          this.searchFormReset()
        }, 200)
        return
      }

      this.$confirm(content, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(async () => {
        let params = {};
        params.c_id = this.cId;
        for (let key in this.searchForm) {
          let value = this.searchForm[key]
          value && (params[key] = value)
        }
        try {
          this.loadingStatus = true
          this.loadingStatus = true
          let data = {
            "current_page" : 1,
            "page_size" : 10000,
            "c_id" : this.cId
          }
          for (let key in this.searchForm) {
            data[key] = this.searchForm[key]
          }
          let {list} = await this.$api.getRealQuestionList(data);
          let arr = []
          for (let question of list) {
            arr = this.selectQuestionIds.filter(item => item.id !== question.id)
          }
          this.selectQuestionIds = arr

          let result = await this.$api.deleteWhereRealQuestion(params);
            result && this.$message.success("删除成功");
            await this.resetRealQuestionList(DEL, this.searchForm)
            await this.summaryRealQuestion()
            this.loadingStatus = false
        } catch (e) {
          this.$message.error('删除失败')
        }
      })
    },

    // 已选搜索
    searchFormSearch() {
      this.pageInfo.current_page = 1;
      this.resetRealQuestionList(DEL, this.searchForm)
    },

    // 已选搜索重置
    searchFormReset() {
      this.pageInfo.current_page = 1;
      this.$refs['search-form'].resetFields();
      this.resetRealQuestionList(DEL)
    },
    setData() {
      let data = {
        page_size: this.pageInfo.page_size,
        current_page: this.pageInfo.current_page,
        c_id: this.cId,
      }
      for (let key in this.searchForm) {
        let value = this.searchForm[key]
        value && (data[key] = value)
      }
      return data
    },
    //已选题目
    async resetRealQuestionList(type, params = {}) {
      if (!this.cId) {
        this.$message("题型ID不存在");
        return;
      }
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);
      for (let param in params) {
        inputData[param] = params[param];
      }
      if (type === DEL) {
        this.$refs['apeTable'].resetCurPageSize()
        inputData["current_page"] = 1
      }
      inputData['c_id'] = this.cId
      let {list, pages} = await this.$api.getRealQuestionList(inputData);
      this.questionAddTableList = list

      this.pagingData.total = pages.total;
      this.loadingStatus = false
      setTimeout(() => {
        for (let selected of this.selectQuestionIds) {
          this.$refs.apeTable.defaultCheckedRow(selected.id)
        }
      }, 200)

      // this.selectedQuestionTableLoading = true;
      //
      // if (!this.cId) {
      //   this.$message("题型ID不存在");
      //   return;
      // }
      //
      // let data = this.setData();
      //
      // let {list, pages} = await this.$api.getRealQuestionList(data);
      //
      // if (!list) {
      //   this.$message.error('数据为空');
      //   return;
      // }
      //
      // if (type === DEL) {
      //   this.questionAddTableList = list;
      // } else {
      //   for (let question of list) {
      //     this.questionAddTableList.push(question);
      //   }
      // }
      //
      // this.pageInfo.total = pages.total;
      //
      // if (pages && this.questionAddTableList.length === pages.total) {
      //   this.$message('已经到底了');
      // }
      //
      // this.selectedQuestionTableLoading = false
    },

    onPageSizeChange(value) {
      if (value) {
        this.pageInfo.page_size = value;
        this.pageInfo.current_page = 1;
        this.pageInfo.total = 0;
        this.resetRealQuestionList(DEL);
      }
    },

    getSelectedQuestionList(selectQuestionIds) {
      if (!selectQuestionIds.length) {
        this.selectQuestionIds = []
        return
      }
      let result = []
      selectQuestionIds.forEach(item => {
        result.push(item.id)
      })
      this.selectQuestionIds = result
    },
    // 切换页码操作
    async switchPaging() {
      this.resetRealQuestionList(NO_DEL, this.searchForm)
    },

    // 全选
    onHandleSelectionAllChange(arr) {
      for (let i of this.questionAddTableList) {
        let index = this.selectQuestionIds.findIndex(item => item.id === i.id)
        if (index > -1) {
          this.selectQuestionIds.splice(index, 1)
        }
      }

      if (arr.length > 0) {
        for (let id of arr) {
          this.selectQuestionIds.push({id: id})
        }
      }


      // if (selected) {
      //   let index = this.selectQuestionIds.findIndex(item => item.id = id)
      //   if (index > -1) {
      //     this.selectQuestionIds.splice(index, 1)
      //   }
      //   this.$nextTick(() => {
      //     this.selectQuestionIds.push({id: id})
      //   })
      // } else {
      //   for (let i of this.questionAddTableList) {
      //     let index = this.selectQuestionIds.findIndex(item => item.id === i.id)
      //     if (index > -1) {
      //       this.selectQuestionIds.splice(index, 1)
      //     }
      //   }
      //   this.$refs.apeTable.clearSelection()
      // }

    },

    // 单选
    handleSelectionChange(id, selected) {
      if (selected) {
        this.selectQuestionIds.push({id: id})
      } else {
        let pos = this.selectQuestionIds.findIndex(item => item.id === id)
        if (pos > -1) {
          this.selectQuestionIds.splice(pos, 1)
        }
      }
    },

  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
  },
}
</script>

<style lang="stylus" scoped>
.color-primary
  color #1890ff

.text-over-hide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex-layout {
  display flex
}

.margin-top-20 {
  margin-top 20px
}

.search-form, .optional-search-box
  margin-top 20px

  /deep/ .el-input
    width 150px

  /deep/ .el-form-item__label
    padding-right 5px

  /deep/ .el-input__inner
    padding: 0 5px

.optional-search-box
  margin-top 0
</style>